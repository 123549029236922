<template lang="pug">
  .matchings(:class="{ loading }")
    TopBar(
      :allow-save="allowSave"
      @save="saveChanges"
      @search="applySearch"
    )
    .table-wrapper
      AppOverlayLoader(:state="loading")
      ShopsCarClassTable(
        :shops="shops"
        :sorting-data="sorting"
        @sorting="handleChangeSorting"
        @select-car-class="updateShopByIndex"
      )
      AppPagination.pagination-padding(
        :total="shopsPagination.total_count"
        :per-page="shopsPagination.per_page"
        :current-page="shopsPagination.current_page"
        @change-pagination-data="handleChangePagination"
      )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  // store modules
  import shopCarClassMatchingModule from "@/config/store/matching/shops_car_class"

  // misc
  import { isEmpty } from "lodash-es"
  import { validateItems } from "@/helpers/matching"
  import { mapGetters } from "vuex"

  const shopCarClassMatchingsMixin = withStoreModule(shopCarClassMatchingModule, {
    resetState: true,
    name: "shopsCarClassMatching",
    readers: {
      shops: "items",
      shopsLoading: "loading",
      shopsPagination: "pagination",
      sorting: "sorting"
    },
    actions: {
      fetchShops: "FETCH_ITEMS",
      updateShops: "UPDATE_ITEMS",
      updateShopByIndex: "UPDATE_ITEM_BY_INDEX",
      applySearch: "APPLY_SEARCH"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    },
    getters: ["itemsUpdated"]
  })

  export default {
    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.isUnsavedItems })
    },

    computed: {
      ...mapGetters(["currentOrganization"]),

      loading({ shopsLoading }) {
        return shopsLoading
      },

      allowSave() {
        return validateItems(this.shops) && !isEmpty(this.itemsUpdated)
      },

      isUnsavedItems() {
        return !isEmpty(this.itemsUpdated)
      }
    },

    components: {
      TopBar: () => import("@/pages/ShopsCarClassMatching/TopBar"),
      ShopsCarClassTable: () => import("@/pages/ShopsCarClassMatching/ShopsCarClassTable"),
      AppPagination: () => import("@/components/elements/AppPagination"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [shopCarClassMatchingsMixin, withConfirmation],

    mounted() {
      this.fetchShops()
    },

    watch: {
      isUnsavedItems(useConfirm) {
        this.setLogoutConfirm(useConfirm)
      }
    },

    methods: {
      fetchCarClasses(params = {}) {
        return this.fetchCarClassesAction({ pagination: { _disabled: true }, ...params })
      },

      saveChanges() {
        this.updateShops()
      },

      handleChangePagination(paginationData) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => this.changePagination(paginationData)
        })
      },

      changePagination(paginationData) {
        this.setPagination({ ...this.shopsPagination, ...paginationData })
        this.fetchShops()
      },

      handleChangeSorting(newSorting) {
        this.$conditionalConfirm({
          useConfirm: this.isUnsavedItems,
          handler: () => this.changeSorting(newSorting)
        })
      },

      changeSorting(newSorting) {
        this.setSorting(newSorting)
        this.setPagination({ ...this.shopsPagination, current_page: 1 })
        this.fetchShops()
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
</style>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .matchings
    .table-wrapper
      position: relative

    &.error
      border: 1px solid $default-red
      color: $default-red

  .pagination-padding
     padding-top: 25px
</style>
