import { api } from "@/config"
import { map, isEmpty } from "lodash-es"
import { normalizeSortingParams } from "@/helpers/vue-good-table"
import { handleUpdateItemsError } from "@/config/store/matching/shared"

const prepareShopsCarClassParams = itemsUpdated =>
  map(itemsUpdated, ({ id, car_classes }) => ({
    id,
    car_classes: map(car_classes, cc => ({ id: cc.id, order: cc.order }))
  }))

export default ({ baseURI }) => ({
  FETCH_ITEMS: async ({ commit, state: { filters, pagination, sorting } }, customParams = {}) => {
    const params = {
      filters,
      pagination,
      sorting: normalizeSortingParams(sorting),
      ...customParams
    }

    const response = await api.get(baseURI, { params })
    const { items, meta } = response.data.data

    commit("SET_ITEMS", items)
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  UPDATE_ITEMS: async (
    { state: { filters, pagination, sorting }, commit, getters: { itemsUpdated } },
    customParams = {}
  ) => {
    const shops = prepareShopsCarClassParams(itemsUpdated)
    try {
      if (isEmpty(shops)) return Promise.resolve()
      const params = { filters, pagination, sorting, ...customParams }

      const response = await api.put(baseURI, { shops, ...params })
      const { items, meta } = response.data.data

      commit("SET_ITEMS", items)
      commit("SET_PAGINATION_DATA", meta.pagination)
    } catch (error) {
      handleUpdateItemsError({ error, itemsUpdated, commit })
    }
  },

  UPDATE_ITEM_BY_INDEX({ commit }, { item, index }) {
    commit("SET_ITEM_BY_INDEX", { item, index })
  },

  APPLY_SEARCH({ commit, dispatch, state }, { search_value }) {
    commit("SET_PAGINATION_DATA", { ...state.pagination, current_page: 1 })
    commit("SET_FILTERS", { search_value })
    dispatch("FETCH_ITEMS", { ...state.filters })
  }
})
