import StoreItemsModule from "@/config/store/StoreItemsModule"

import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import actionsBuild from "./actions"
import { commonStateBuild, commonGetters } from "../shared"

const BASE_URI = "/shops_car_class_matching"
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: true,
  withPagination: true,
  withSorting: true
})

store.mergeState(commonStateBuild())
store.mergeGetters(commonGetters)

const { FETCH_ITEMS, UPDATE_ITEMS, UPDATE_ITEM_BY_INDEX, APPLY_SEARCH } = actionsBuild({ baseURI: BASE_URI })

store.mergeActions({ UPDATE_ITEM_BY_INDEX, APPLY_SEARCH })
store.mergeActions({ FETCH_ITEMS, UPDATE_ITEMS }, withLoading)

export default store
